const messages = {
  DEADLINE_HOURS: 'Godziny',
  COMMON__CONFIRM: 'Potwierdź',
  COMMON__EDIT: 'Edytuj',
  COMMON__SUCCESS: 'Sukces',
  COMMON__INFO: 'Informacje',
  COMMON__WARNING: 'Ostrzeżenie',
  COMMON__FAILURE: 'Niepowodzenie',
  COMMON__MODAL_CLOSE: 'Zamknij',
  COMMON__CONTINUE: 'Kontynuuj',
  COMMON__LAST_UPDATED: 'Ostatnia aktualizacja',
  COMMON__UPDATED_BY: 'Zaktualizowano przez',
  COMMON__LANGUAGE_MODAL_CHOOSE: 'Wybierz język',
  COMMON__LANGUAGE: 'Język',
  COMMON__NOT_FOUND_TEXT:
    'Przepraszamy, ale nie można znaleźć strony, której szukasz. Możesz wrócić do ',
  COMMON__CLEAR_SELECTION: 'Wyczyść wybór',
  COMMON__DELETE: 'Usuń',
  COMMON__SELECTED: '%{count} wybrane',
  COMMON__DESCRIPTION: 'Opis',
  COMMON__COUNTRY: 'Kraj',
  COMMON__VAT_NUMBER: 'Numer VAT',
  COMMON__STATUS: 'Status',
  COMMON__TRY_AGAIN: 'Spróbuj ponownie',
  COMMON__DATA_FETCH_FAILED:
    'Coś poszło nie tak podczas pobierania danych. Spróbuj ponownie później.',
  COMMON__YES: 'Tak',
  COMMON__NO: 'Nie',
  COMMON__OK: 'Ok',
  COMMON_LOADING: 'Ładowanie',
  COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP: 'Wskazówka: Przeciągnij tabelę poziomo',
  COMMON_NAME: 'Nazwa',
  COMMON_ID: 'Id',
  COMMON__SYSTEM: 'System',
  COMMON__ADD_VACANCY: 'Dodaj wakat',
  VALIDATIONS__EMAIL: 'Nieprawidłowy adres e-mail',
  VALIDATIONS__INFORMATION: 'Your message might contain the offensive words'
};

export default messages;
