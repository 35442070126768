const messages = {
  DEADLINE_HOURS: 'Ore',
  COMMON__CONFIRM: 'Conferma',
  COMMON__EDIT: 'Modifica',
  COMMON__SUCCESS: 'Successo',
  COMMON__INFO: 'Info',
  COMMON__WARNING: 'Attenzione',
  COMMON__FAILURE: 'Fallimento',
  COMMON__MODAL_CLOSE: 'Chiudi',
  COMMON__CONTINUE: 'Continua',
  COMMON__LAST_UPDATED: 'Ultimo aggiornamento',
  COMMON__UPDATED_BY: 'Aggiornato da',
  COMMON__LANGUAGE_MODAL_CHOOSE: 'Scegli lingua',
  COMMON__LANGUAGE: 'Lingua',
  COMMON__NOT_FOUND_TEXT:
    'Ci dispiace, ma la pagina che stavi cercando non è stata trovata. Puoi tornare a ',
  COMMON__CLEAR_SELECTION: 'Annulla selezione',
  COMMON__DELETE: 'Elimina',
  COMMON__SELECTED: '%{count} selezionato',
  COMMON__DESCRIPTION: 'Descrizione',
  COMMON__COUNTRY: 'Paese',
  COMMON__VAT_NUMBER: 'Partita IVA',
  COMMON__STATUS: 'Stato',
  COMMON__TRY_AGAIN: 'Riprova',
  COMMON__DATA_FETCH_FAILED:
    'Qualcosa è andato storto durante il recupero dei dati. Per favore riprova più tardi.',
  COMMON__YES: 'Sì',
  COMMON__NO: 'No',
  COMMON__OK: 'Ok',
  COMMON_LOADING: 'Caricamento',
  COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP: 'Suggerimento: Trascina la tabella orizzontalmente',
  COMMON_NAME: 'Nome',
  COMMON_ID: 'Id',
  COMMON__SYSTEM: 'Sistema',
  COMMON__ADD_VACANCY: 'Aggiungi un posto vacante',
  VALIDATIONS__EMAIL: 'Indirizzo e-mail non valido',
  VALIDATIONS__INFORMATION: 'Your message might contain the offensive words'
};

export default messages;
