const messages = {
  DEADLINE_HOURS: 'Heures',
  COMMON__CONFIRM: 'Confirmer',
  COMMON__EDIT: 'Editer',
  COMMON__SUCCESS: 'Succès',
  COMMON__INFO: 'Info',
  COMMON__WARNING: 'Attention',
  COMMON__FAILURE: 'Insuccès',
  COMMON__MODAL_CLOSE: 'Fermer',
  COMMON__CONTINUE: 'Continuer',
  COMMON__LAST_UPDATED: 'Dernière mise à jour',
  COMMON__UPDATED_BY: 'Mis à jour par',
  COMMON__LANGUAGE_MODAL_CHOOSE: 'Choisissez la langue',
  COMMON__LANGUAGE: 'Langue',
  COMMON__NOT_FOUND_TEXT:
    "Désolé, mais la page que vous recherchez n'a pu être trouvée. Vous pouvez revenir à ",
  COMMON__DELETE: 'Supprimer',
  COMMON__COUNTRY: 'Pays',
  COMMON__DATA_FETCH_FAILED:
    "Quelque chose s'est mal passé lors de la récupération des données. Veuillez réessayer plus tard.",
  COMMON__YES: 'Oui',
  COMMON__NO: 'Non',
  COMMON__OK: 'Ok',
  COMMON_LOADING: 'Chargement',
  COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP: 'Allusion: Glisse horizontalement',
  COMMON_NAME: 'Nom',
  COMMON_ID: 'Id',
  COMMON__SYSTEM: 'Système',
  COMMON__LOCATION: 'Localisation',
  COMMON__CREATED_AT: 'Créé à',
  COMMON__INFORMATION: 'Informations',
  COMMON__CONTACT: 'Informations sur les contacts',
  COMMON__ADD_VACANCY: 'Ajouter un poste vacant',
  VALIDATIONS__EMAIL: 'Adresse électronique invalide',
  VALIDATIONS__INFORMATION: 'Your message might contain the offensive words'
};

export default messages;
