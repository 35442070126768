export const citiesRu = [
  { cityId: '4', label: 'Весь Израиль' },
  { cityId: '1', label: 'Север страны' },
  { cityId: '2', label: 'Алфей Менаше' },
  { cityId: '3', label: 'Арад' },
  { cityId: '5', label: 'Центр страны' },
  { cityId: '6', label: 'Юг страны' },
  { cityId: '7', label: 'Азур' },
  { cityId: '8', label: 'Акко' },
  { cityId: '9', label: 'Атлит' },
  { cityId: '10', label: 'Ариэль' },
  { cityId: '11', label: 'Афула' },
  { cityId: '12', label: 'Ашдод' },
  { cityId: '13', label: 'Ашкелон' },
  { cityId: '14', label: 'Бат Ям' },
  { cityId: '15', label: 'Беер Яков' },
  { cityId: '16', label: 'Бейт Джан' },
  { cityId: '17', label: 'Бейт Шеан' },
  { cityId: '18', label: 'Бейт Шемеш' },
  { cityId: '19', label: 'Бейт Эль' },
  { cityId: '20', label: 'Бейтар Илит' },
  { cityId: '21', label: 'Беэр Шева' },
  { cityId: '22', label: 'Биньямина' },
  { cityId: '23', label: 'Бней Аиш' },
  { cityId: '24', label: 'Бней Брак' },
  { cityId: '25', label: 'Ган Явне' },
  { cityId: '26', label: 'Ганей Тиква' },
  { cityId: '27', label: 'Гедера' },
  { cityId: '28', label: 'Герцлия' },
  { cityId: '29', label: 'Гиват Зеев' },
  { cityId: '30', label: 'Гиватаим' },
  { cityId: '31', label: 'Димона' },
  { cityId: '32', label: 'Зихрон Яaков' },
  { cityId: '33', label: 'Иерусалим' },
  { cityId: '34', label: 'Йерухам' },
  { cityId: '35', label: 'Йехуд' },
  { cityId: '36', label: 'Йокнеам Илит' },
  { cityId: '37', label: 'Кадима' },
  { cityId: '38', label: 'Кармиель' },
  { cityId: '39', label: 'Карней Шомрон' },
  { cityId: '40', label: 'Кацрин' },
  { cityId: '41', label: 'Кдумим' },
  { cityId: '42', label: 'Кейсария' },
  { cityId: '43', label: 'Кирьят Арба' },
  { cityId: '44', label: 'Кирьят Ата' },
  { cityId: '45', label: 'Кирьят Бял.' },
  { cityId: '46', label: 'Кирьят Гат' },
  { cityId: '47', label: 'Кирьят Малахи' },
  { cityId: '48', label: 'Кирьят Моцкин' },
  { cityId: '49', label: 'Кирьят Оно' },
  { cityId: '50', label: 'Кирьят Шмона' },
  { cityId: '51', label: 'Кирьят Ям' },
  { cityId: '52', label: 'Кохав Яир' },
  { cityId: '53', label: 'Кфар Саба' },
  { cityId: '54', label: 'Лод' },
  { cityId: '55', label: 'Маале Адумим' },
  { cityId: '56', label: 'Мазкерет Батья' },
  { cityId: '57', label: 'Мевасерет Цион' },
  { cityId: '58', label: 'Мигдаль Ха-Эмек' },
  { cityId: '59', label: 'Мицпе Рамон' },
  { cityId: '60', label: 'Модиин' },
  { cityId: '61', label: 'Наария' },
  { cityId: '62', label: 'Натания' },
  { cityId: '63', label: 'Нацерет' },
  { cityId: '64', label: 'Нацрат Илит' },
  { cityId: '65', label: 'Нес Циона' },
  { cityId: '66', label: 'Нетивот' },
  { cityId: '67', label: 'Нешер' },
  { cityId: '68', label: 'Омер' },
  { cityId: '69', label: 'Ор Акива' },
  { cityId: '70', label: 'Ор Йегуда' },
  { cityId: '71', label: 'Офаким' },
  { cityId: '72', label: 'Пардес Хана' },
  { cityId: '73', label: 'Пардессия' },
  { cityId: '74', label: 'Петах Тиква' },
  { cityId: '75', label: 'Раанана' },
  { cityId: '76', label: 'Рамат Ган' },
  { cityId: '77', label: 'Рамла' },
  { cityId: '78', label: 'Рамат Ха Шарон' },
  { cityId: '79', label: 'Реховот' },
  { cityId: '80', label: 'Ришон ле Цион' },
  { cityId: '81', label: 'Рош Аин' },
  { cityId: '82', label: 'Рош Пина' },
  { cityId: '83', label: 'Сдерот' },
  { cityId: '84', label: 'Тверия' },
  { cityId: '85', label: 'Тель Авив' },
  { cityId: '86', label: 'Тира' },
  { cityId: '87', label: 'Тират Кармель' },
  { cityId: '88', label: 'Хайфа' },
  { cityId: '89', label: 'Хацор Ха-Глилит' },
  { cityId: '90', label: 'Хедера' },
  { cityId: '91', label: 'Ход Ха Шарон' },
  { cityId: '92', label: 'Холон' },
  { cityId: '93', label: 'Цфат' },
  { cityId: '94', label: 'Шломи' },
  { cityId: '95', label: 'Кфар-Шмарьягу' },
  { cityId: '96', label: 'Метула' },
  { cityId: '97', label: 'Шохам' },
  { cityId: '98', label: 'Эвен Йегуда' },
  { cityId: '99', label: 'Эйлат' },
  { cityId: '100', label: 'Явне' },
  { cityId: '101', label: 'Кирьят Хаим' },
  { cityId: '102', label: 'Маалот-Таршиха' }
];

export const citiesEn: { value: string; label: string }[] = [
  { value: '0', label: 'Whole Israel' },
  { value: '1', label: 'Northern Region' },
  { value: '2', label: 'Central Region' },
  { value: '3', label: 'Southern Region' },
  { value: '1', label: 'Azur' },
  { value: '2', label: 'Akko' },
  { value: '3', label: 'Alfei Menashe' },
  { value: '4', label: 'Arad' },
  { value: '5', label: 'Ariel' },
  { value: '6', label: 'Atlit' },
  { value: '7', label: 'Afula' },
  { value: '8', label: 'Ashdod' },
  { value: '9', label: 'Ashkelon' },
  { value: '10', label: 'Bat Yam' },
  { value: '11', label: "Be'er Yaakov" },
  { value: '12', label: 'Beit Jan' },
  { value: '13', label: 'Beit Shean' },
  { value: '14', label: 'Beit Shemesh' },
  { value: '15', label: 'Beit El' },
  { value: '16', label: 'Beitar Illit' },
  { value: '20', label: 'Beersheba' },
  { value: '17', label: 'Binyamina' },
  { value: '18', label: 'Bnei Ayish' },
  { value: '19', label: 'Bnei Brak' },
  { value: '21', label: 'Gan Yavne' },
  { value: '22', label: 'Ganei Tikva' },
  { value: '26', label: 'Gedera' },
  { value: '23', label: 'Herzliya' },
  { value: '25', label: "Givat Ze'ev" },
  { value: '24', label: 'Givatayim' },
  { value: '27', label: 'Dimona' },
  { value: '28', label: 'Zichron Yaakov' },
  { value: '32', label: 'Jerusalem' },
  { value: '29', label: 'Yeruham' },
  { value: '30', label: 'Yehud' },
  { value: '31', label: 'Yokneam Illit' },
  { value: '33', label: 'Kadima' },
  { value: '34', label: 'Karmiel' },
  { value: '35', label: 'Karnei Shomron' },
  { value: '36', label: 'Katzrin' },
  { value: '37', label: 'Kedumim' },
  { value: '38', label: 'Caesarea' },
  { value: '39', label: 'Kiryat Arba' },
  { value: '40', label: 'Kiryat Ata' },
  { value: '41', label: 'Kiryat Bialik' },
  { value: '42', label: 'Kiryat Gat' },
  { value: '43', label: 'Kiryat Malachi' },
  { value: '44', label: 'Kiryat Motzkin' },
  { value: '45', label: 'Kiryat Ono' },
  { value: '96', label: 'Kiryat Haim' },
  { value: '46', label: 'Kiryat Shmona' },
  { value: '47', label: 'Kiryat Yam' },
  { value: '48', label: 'Kochav Yair' },
  { value: '49', label: 'Kfar Saba' },
  { value: '98', label: 'Kfar Shmaryahu' },
  { value: '50', label: 'Lod' },
  { value: '51', label: 'Maale Adumim' },
  { value: '97', label: "Ma'alot-Tarshiha" },
  { value: '52', label: 'Mazkeret Batya' },
  { value: '53', label: 'Mevaseret Zion' },
  { value: '99', label: 'Metula' },
  { value: '54', label: 'Migdal HaEmek' },
  { value: '55', label: 'Mitzpe Ramon' },
  { value: '56', label: "Modi'in" },
  { value: '57', label: 'Nahariya' },
  { value: '58', label: 'Netanya' },
  { value: '59', label: 'Nazareth' },
  { value: '60', label: 'Nesher' },
  { value: '61', label: 'Ness Ziona' },
  { value: '62', label: 'Netivot' },
  { value: '63', label: 'Nesher' },
  { value: '64', label: 'Omer' },
  { value: '65', label: 'Or Akiva' },
  { value: '66', label: 'Or Yehuda' },
  { value: '67', label: 'Ofakim' },
  { value: '69', label: 'Pardes Hanna' },
  { value: '68', label: 'Pardesiya' },
  { value: '70', label: 'Petah Tikva' },
  { value: '71', label: 'Raanana' },
  { value: '72', label: 'Ramat Gan' },
  { value: '73', label: 'Ramat Hasharon' },
  { value: '74', label: 'Ramla' },
  { value: '75', label: 'Rehovot' },
  { value: '76', label: 'Rishon LeZion' },
  { value: '78', label: 'Rosh HaAyin' },
  { value: '77', label: 'Rosh Pina' },
  { value: '79', label: 'Sderot' },
  { value: '80', label: 'Tiberias' },
  { value: '81', label: 'Tel Aviv' },
  { value: '82', label: 'Tirat Carmel' },
  { value: '83', label: 'Haifa' },
  { value: '84', label: 'Hatzor HaGlilit' },
  { value: '85', label: 'Hadera' },
  { value: '86', label: 'Hod HaSharon' },
  { value: '87', label: 'Holon' },
  { value: '88', label: 'Zefat' },
  { value: '89', label: 'Shlomi' },
  { value: '91', label: 'Shoham' },
  { value: '92', label: 'Even Yehuda' },
  { value: '93', label: 'Eilat' },
  { value: '94', label: 'Yavne' }
];
