import { TFunction } from 'i18next';

export const validateEmail = (t: TFunction, _: any, value: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (value && !emailRegex.test(value)) {
    return Promise.reject(t(`VALIDATIONS__EMAIL`));
  }
  return Promise.resolve();
};

export const validatePhoneNumber = (t: TFunction, _: any, value: string) => {
  const phoneRegex = /^[0-9]\d{8,9}$/;
  if (value && !phoneRegex.test(value)) {
    return Promise.reject(t(`VALIDATIONS__PHONE`));
  }
  return Promise.resolve();
};

// Define a list of swear words to exclude
const excludedSwearWords = ['fuck', 'bitch', 'whore'];

export const validateInformation = (t: TFunction, _: any, value: string) => {
  // Create a regular expression with negative lookahead for swear words
  // const informationRegex = new RegExp(`^(?!.*\\b(?:${excludedSwearWords.join('|')})\\b).*$`);

  // if (value && !informationRegex.test(value)) {
  //   return Promise.reject(t(`VALIDATIONS__INFORMATION`));
  // }
  return Promise.resolve();
};
