// components/Header.js
'use client';
// components/Header.js
import i18n from '@/i18n/i18n';
import { Image, Menu, notification } from 'antd';
import { useRouter } from 'next/navigation';
import { Metadata } from 'next/types';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddVacancyModal from '../AddVacancyModal/AddVacancyModal';
import SearchButton from '../SearchButton/SearchButton';
import styles from './Header.module.scss';
export type postResponseT = {
  response: string;
  time: string;
  ip?: string;
};

export const metadata: Metadata = {
  title: 'Поиск работы в Израиле - Найдите свою идеальную вакансию',
  description:
    'Поиск работы в Израиле на нашем сайте. Мы предлагаем вакансии от лучших работодателей по всей стране. Легко находите работу!'
};

const Header: React.FC<any> = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [postResponse, setPostResponse] = useState<postResponseT>();
  const { t } = useTranslation();
  console.log(`ORGANIZATION__CONTACT t => ${t('ORGANIZATION__CONTACT')}`);
  const catAsciiSuccess = `Success, you posted`;

  useEffect(() => {
    i18n.changeLanguage('ru');
  }, []);

  const catAsciiWarning = `Warning too many request`;

  const router = useRouter();
  const menuItems: any = useMemo(() => {
    const items = [
      {
        key: 'about',
        label: t(`COMMON__HOME`),
        onClick: () => {
          router.push('/');
        }
      },
      {
        key: 'addVacancy',
        label: t('COMMON__ADD_VACANCY'),
        onClick: () => {
          setModalVisible(true);
        }
      },
      {
        key: 'favorites',
        label: t('COMMON_FAVORITE'),
        onClick: () => {
          router.push('/favorites');
        }
      }
    ];

    return items;
  }, [t, setModalVisible]);

  const openNotification = (postResponse: any) => {
    console.log(`inside of openNotification postResponse`, postResponse);
    if (postResponse?.resultOfPost === 'response.ok') {
      console.log(`openNotification response.ok`);
      api.success({
        message: 'Vacancy',
        description: <pre style={{ width: '500px' }}>{catAsciiSuccess}</pre>,
        duration: 2,
        style: { width: '500px' }
      });
    } else if (postResponse?.resultOfPost === '!response.ok') {
      console.log(`openNotification !response.ok`);
      api.warning({
        message: 'Vacancy',
        description: <pre style={{ width: '500px' }}>{catAsciiWarning}</pre>,
        duration: 2,
        style: { width: '500px' }
      });
    }
  };

  useEffect(() => {
    console.log(`currentResultOfPost : `, postResponse);

    openNotification(postResponse);
  }, [postResponse]);

  return (
    <header className={styles.containerHeader}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '12fr 1fr'
        }}
      >
        <Menu
          mode="horizontal"
          style={{
            display: 'flex',
            backgroundColor: '#9ec5ff',
            overflow: 'hidden'
          }}
          items={menuItems}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'rgb(158, 197, 255)',
            alignItems: 'center',
            paddingRight: '8px'
          }}
        >
          <Suspense>
            <SearchButton />
          </Suspense>
        </div>
      </div>
      {modalVisible && (
        <AddVacancyModal setModalVisible={setModalVisible} setPostResponseFunc={setPostResponse} />
      )}
      <Image
        preview={false}
        className={`${styles.Image}`}
        src="/LogoPogo.jpeg"
        alt="Поиск работы в Израиле"
      />
    </header>
  );
};

export default Header;
