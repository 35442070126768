import { City } from '@/types';
import { useEffect, useState } from 'react';

const useVacancies = () => {
  const [ammountOfVacancies, setAmmountVacancies] = useState<any[]>([]);
  const [ammountOfVacanciesPerCity, setAmmountOfVacanciesPerCity] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  console.log(`ammountOfVacanciesPerCity`, ammountOfVacanciesPerCity);

  useEffect(() => {
    const fetchVacancies = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`/api/getAmmountOfVacancies`, {
          method: 'GET'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setAmmountVacancies(data.data.totalCount);
        setAmmountOfVacanciesPerCity(data.data.amountOfVacanciesByLocationResult);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchVacancies();
  }, []);

  return { ammountOfVacanciesPerCity, ammountOfVacancies, loading, error };
};

export default useVacancies;
