const messages = {
  DEADLINE_HOURS: 'Stunden',
  COMMON__CONFIRM: 'Bestätigen',
  COMMON__EDIT: 'Bearbeiten',
  COMMON__SUCCESS: 'Erfolg',
  COMMON__INFO: 'Info',
  COMMON__WARNING: 'Warnung',
  COMMON__FAILURE: 'Fehler',
  COMMON__MODAL_CLOSE: 'Schließen',
  COMMON__CONTINUE: 'Fortfahren',
  COMMON__LAST_UPDATED: 'Letzte Aktualisierung',
  COMMON__UPDATED_BY: 'Aktualisiert von',
  COMMON__LANGUAGE_MODAL_CHOOSE: 'Sprache auswählen',
  COMMON__LANGUAGE: 'Sprache',
  COMMON__NOT_FOUND_TEXT:
    'Sorry, aber die Seite, die Sie gesucht haben, konnte nicht gefunden werden. Sie können zurückkehren zu ',
  COMMON__CLEAR_SELECTION: 'Auswahl aufheben',
  COMMON__DELETE: 'Löschen',
  COMMON__SELECTED: '%{count} ausgewählt',
  COMMON__DESCRIPTION: 'Beschreibung',
  COMMON__COUNTRY: 'Land',
  COMMON__VAT_NUMBER: 'Umsatzsteuer-Identifikationsnummer',
  COMMON__STATUS: 'Status',
  COMMON__TRY_AGAIN: 'Versuchen Sie es erneut',
  COMMON__DATA_FETCH_FAILED:
    'Beim Abrufen der Daten ist etwas schief gelaufen. Bitte versuchen Sie es später noch einmal.',
  COMMON__YES: 'Ja',
  COMMON__NO: 'Nein',
  COMMON__OK: 'Ok',
  COMMON_LOADING: 'Laden',
  COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP: 'Tipp: Tabelle horizontal ziehen',
  COMMON_NAME: 'Name',
  COMMON_ID: 'Id',
  COMMON__SYSTEM: 'System',
  COMMON__LOCATION: 'Standort',
  COMMON__ADD_VACANCY: 'Stellenangebot hinzufügen',
  VALIDATIONS__EMAIL: 'Ungültige E-Mail Adresse',
  VALIDATIONS__INFORMATION: 'Your message might contain the offensive words'
};

export default messages;
