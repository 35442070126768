// contexts/MyContext.tsx
'use client';
import { ReactNode, createContext, useContext, useState } from 'react';
import { Vacancy, ammountOfVacanciesCount } from '../types';

interface Filters {
  timeRange?: any;
  chosenCities?: string[];
}

type MyContextType = {
  vacanciesFromApi: Vacancy[];
  updateVacanciesFromApi: (newValue: any) => void;

  vacancies: Vacancy[];
  updateVacancies: (newValue: any) => void;

  loadingVacanciesStatus: boolean;
  updateLoadingVacanciesStatus: (nweStatus: boolean) => void;

  searchFilters: Filters | undefined; // Corrected the property name from FIlters to Filters
  updateSearchFilters: (newFilter: Partial<Filters>) => void; // Corrected the parameter name from nweFilter to newFilter, and added the correct type

  ammountOfVacancies: ammountOfVacanciesCount;
  updateAmmountOfVacancies: (nweFilter: any) => void;

  resultOfPost: any;
  updateResultOfPost: (nweFilter: any) => void;

  clickedVacancy: Vacancy | undefined;
  updateClickedVacancy: (nweFilter: any) => void;
};

const MyContext = createContext<MyContextType | undefined>(undefined);

type MyProviderProps = {
  children: ReactNode;
};

export const MyProvider: React.FC<MyProviderProps> = ({ children }) => {
  //globaly fetched from API big chunk of whole vacancies
  const [vacanciesFromApi, setVacanciesFromApi] = useState([]);
  //visible to user vacancies
  const [vacancies, setVacancies] = useState([]);
  //status for loading
  const [loadingVacanciesStatus, setLoadingVacanciesStatus] = useState(false);
  //search filters applyed to the vacanciesFromApi and then -> set to vacancies
  const [searchFilters, setSearchFilters] = useState<Filters | undefined>();
  //ammount of vacancies
  const [ammountOfVacancies, setAmmountOfVacancies] = useState([]);
  //ammount of vacancies
  const [resultOfPost, setResultOfPost] = useState(false);
  //current chosen clicked vacancy, need to pass data to component on page
  const [clickedVacancy, setClickedVacancy] = useState<Vacancy>();

  //every time vacanciesFromApi or searchFilters changes we reset the vacancies
  // useEffect(() => {
  //   console.log(`we set vacancies to big set of vacanciesFromApi`);
  //   setVacancies(vacanciesFromApi);
  // }, [vacanciesFromApi]);

  const updateClickedVacancy = (newValue: any) => {
    setClickedVacancy(newValue);
  };

  const updateVacancies = (newValue: any) => {
    setVacancies(newValue);
  };

  const updateLoadingVacanciesStatus = (nweStatus: boolean) => {
    setLoadingVacanciesStatus(nweStatus);
  };

  const updateVacanciesFromApi = (newValue: any) => {
    setVacanciesFromApi(newValue);
  };

  const updateSearchFilters = (newValue: any) => {
    setSearchFilters(newValue);
  };

  const updateAmmountOfVacancies = (newValue: any) => {
    setAmmountOfVacancies(newValue);
  };

  const updateResultOfPost = (newValue: any) => {
    setResultOfPost(newValue);
  };

  const contextValue: MyContextType = {
    vacanciesFromApi,
    updateVacanciesFromApi,

    vacancies,
    updateVacancies,

    loadingVacanciesStatus,
    updateLoadingVacanciesStatus,

    searchFilters,
    updateSearchFilters,

    ammountOfVacancies,
    updateAmmountOfVacancies,

    resultOfPost,
    updateResultOfPost,

    clickedVacancy,
    updateClickedVacancy
  };

  return <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>;
};

export const useMyContext = (): MyContextType => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};
