const messages = {
  DEADLINE_HOURS: 'Uren',
  COMMON__CONFIRM: 'Confirmeer',
  COMMON__EDIT: 'Aanpassen',
  COMMON__SUCCESS: 'Succes',
  COMMON__INFO: 'Info',
  COMMON__WARNING: 'Opgelet',
  COMMON__FAILURE: 'Mislukt',
  COMMON__MODAL_CLOSE: 'Sluiten',
  COMMON__CONTINUE: 'Volgende',
  COMMON__LAST_UPDATED: 'Laatst bijgewerkt',
  COMMON__UPDATED_BY: 'Bijgewerkt door',
  COMMON__LANGUAGE_MODAL_CHOOSE: 'Kies taal',
  COMMON__LANGUAGE: 'Taal',
  COMMON__NOT_FOUND_TEXT:
    'Sorry, maar de pagina die u zocht kon niet worden gevonden. Je kan terugkeren naar ',
  COMMON__CLEAR_SELECTION: 'Selectie ongedaan maken',
  COMMON__DELETE: 'Verwijderen',
  COMMON__SELECTED: '%{count} geselecteerd',
  COMMON__DESCRIPTION: 'Beschrijving',
  COMMON__COUNTRY: 'Land',
  COMMON__VAT_NUMBER: 'BTW nummer',
  COMMON__STATUS: 'Status',
  COMMON__TRY_AGAIN: 'Probeer het nog eens',
  COMMON__DATA_FETCH_FAILED:
    'Er liep iets mis met het laden van de data. Probeer het later opnieuw.',
  COMMON__YES: 'Ja',
  COMMON__NO: 'Nee',
  COMMON__OK: 'Ok',
  COMMON_LOADING: 'Laden',
  COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP: 'Tip: Sleep horizontaal',
  COMMON_NAME: 'Naam',
  COMMON_ID: 'Id',
  COMMON__SYSTEM: 'Systeem',
  COMMON__ADD_VACANCY: 'Vacature toevoegen',
  VALIDATIONS__EMAIL: 'Ongeldig e-mailadres',
  VALIDATIONS__INFORMATION: 'Your message might contain the offensive words'
};

export default messages;
