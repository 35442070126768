import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import messagesDe from '../translations/de';
import messagesEn from '../translations/en';
import messagesFr from '../translations/fr';
import messagesIt from '../translations/it';
import messagesNl from '../translations/nl';
import messagesPl from '../translations/pl';
import messagesRu from '../translations/ru';
interface LanguageSelection {
  [key: string]: string;
}

export const languageSelection: LanguageSelection = {
  en: 'English',
  nl: 'Nederlands',
  fr: 'Français',
  it: 'Italiano',
  de: 'Deutsch',
  pl: 'Polski',
  ru: 'Russian'
};

const resources = {
  en: {
    translation: {
      ...messagesEn
    }
  },
  fr: {
    translation: {
      ...messagesFr
    }
  },
  de: {
    translation: {
      ...messagesDe
    }
  },
  nl: {
    translation: {
      ...messagesNl
    }
  },
  it: {
    translation: {
      ...messagesIt
    }
  },
  pl: {
    translation: {
      ...messagesPl
    }
  },
  ru: {
    translation: {
      ...messagesRu
    }
  }
  // Add more languages dynamically
};
// Initialize i18next with resources

i18n.use(initReactI18next).init({
  lng: 'ru', // Set the default language
  fallbackLng: 'ru', // Fallback language
  resources: { ...resources }
});

// Initialize i18next with the default language and resources
// i18n.init({
//   lng: 'en', // Set the default language
//   fallbackLng: 'en', // Fallback language
//   resources: {
//     en: {
//       translation: languageSelection
//     }
//   }
// });

export default i18n;
