const messages = {
  ADD_NEW_VACANCY: 'Add New Vacancy',
  DEADLINE_HOURS: 'Hours',
  COMMON__CONFIRM: 'Confirm',
  COMMON_FAVORITE: 'Favorite',
  COMMON_CANCEL: 'Cancel',
  COMMON_SUBMIT: 'Submit',
  COMMON__EDIT: 'Edit',
  COMMON__SUCCESS: 'Success',
  COMMON__INFO: 'Info',
  COMMON__WARNING: 'Warning',
  COMMON__FAILURE: 'Failure',
  COMMON__MODAL_CLOSE: 'Close',
  COMMON__CONTINUE: 'Continue',
  COMMON__LAST_UPDATED: 'Last updated',
  COMMON__UPDATED_BY: 'Updated by',
  COMMON__LANGUAGE_MODAL_CHOOSE: 'Choose language',
  COMMON__LANGUAGE: 'Language',
  COMMON__NOT_FOUND_TEXT:
    'Sorry, but the page you were looking for could not be found. You can return to ',
  COMMON__CLEAR_SELECTION: 'Clear selection',
  COMMON__DELETE: 'Delete',
  COMMON__SELECTED: '%{count} selected',
  COMMON__DESCRIPTION: 'Description',
  COMMON__COUNTRY: 'Country',
  COMMON__VAT_NUMBER: 'VAT number',
  COMMON__STATUS: 'Status',
  COMMON__TRY_AGAIN: 'Try again',
  COMMON__DATA_FETCH_FAILED:
    'Something went wrong while fetching the data. Please try again later.',
  COMMON__YES: 'Yes',
  COMMON__NO: 'No',
  COMMON__OK: 'Ok',
  COMMON_LOADING: 'Loading',
  COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP: 'Tip: Drag table horizontally',
  COMMON_NAME: 'Name',
  COMMON_ID: 'Id',
  COMMON__SYSTEM: 'System',
  COMMON__LOCATION: 'City',
  COMMON__CREATED_AT: 'Created at',
  COMMON__INFORMATION: 'Information',
  COMMON__CONTACT: 'Contact information',
  COMMON_ENTER_CONTACT_INFORMATION: 'Enter contact information',
  COMMON_ENTER_INFORMATION: 'Enter information',
  COMMON__ADD_VACANCY: 'Add vacancy',
  COMMON__HOME: 'Home',
  VALIDATIONS__EMAIL: 'Invalid email address',
  VALIDATIONS__PHONE: 'Invalid phone number',
  COMMON__PHONE_COPIED: 'Number copied!',
  VALIDATIONS__INFORMATION: 'Your message might contain the offensive words',
  ADD_FAVORITES: 'Add to favorites',
  REMOVE_FROM_FAVORITE: 'Remove from favorites',
  UPLOAD_IAMGE: `Upload image`
};

export default messages;
