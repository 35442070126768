const messages = {
  ADD_NEW_VACANCY: 'Добавить новую вакансию',
  DEADLINE_HOURS: 'Часы',
  COMMON__CONFIRM: 'Подтвердить',
  COMMON_CANCEL: 'Отмена',
  COMMON_SUBMIT: 'Отправить',
  COMMON_FAVORITE: 'Избранное',
  COMMON__EDIT: 'Редактировать',
  COMMON__SUCCESS: 'Успех',
  COMMON__INFO: 'Информация',
  COMMON__WARNING: 'Предупреждение',
  COMMON__FAILURE: 'Неудача',
  COMMON__MODAL_CLOSE: 'Закрыть',
  COMMON__CONTINUE: 'Продолжить',
  COMMON__LAST_UPDATED: 'Последнее обновление',
  COMMON__UPDATED_BY: 'Обновлено до',
  COMMON__LANGUAGE_MODAL_CHOOSE: 'Выберите язык',
  COMMON__LANGUAGE: 'Язык',
  COMMON__NOT_FOUND_TEXT:
    'Извините, но страница, которую вы искали, не найдена. Вы можете вернуться на ',
  COMMON__CLEAR_SELECTION: 'Очистить выбор',
  COMMON__DELETE: 'Удалить',
  COMMON__SELECTED: '%{count} выбранных',
  COMMON__DESCRIPTION: 'Описание',
  COMMON__COUNTRY: 'Страна',
  COMMON__VAT_NUMBER: 'Номер НДС',
  COMMON__STATUS: 'Статус',
  COMMON__TRY_AGAIN: 'Повторите попытку',
  COMMON__DATA_FETCH_FAILED:
    'Что-то пошло не так при получении данных. Пожалуйста, повторите попытку позже',
  COMMON__YES: 'Да',
  COMMON__NO: 'Нет',
  COMMON__OK: 'Хорошо',
  COMMON_LOADING: 'Загрузка',
  COMMON_MOBILE_TABLE_HORIZONTAL_SCROLL_TIP: 'Совет: Перетаскивание таблицы по горизонтали',
  COMMON_NAME: 'Имя',
  COMMON_ID: 'Id',
  COMMON__SYSTEM: 'Система',
  COMMON__LOCATION: 'Город',
  COMMON__CREATED_AT: 'Создан в',
  COMMON__INFORMATION: 'Информация',
  COMMON__CONTACT: 'Контактная информация',
  COMMON_ENTER_CONTACT_INFORMATION: 'Введите контактную информацию',
  COMMON_ENTER_INFORMATION: 'Введите информацию',
  COMMON__ADD_VACANCY: 'Добавить вакансию',
  COMMON__HOME: 'Главная',
  VALIDATIONS__EMAIL: 'Неверный адрес электронной почты',
  VALIDATIONS__PHONE: 'Неверный номер телефона',
  COMMON__PHONE_COPIED: 'Номер скопирован!',
  VALIDATIONS__INFORMATION: 'Ваше сообщение может содержать оскорбительные слова',
  ADD_FAVORITES: 'Добавить в избранное',
  REMOVE_FROM_FAVORITE: 'Удалить из избранного',
  UPLOAD_IAMGE: `Загрузить изображение`,
  EXPAND_TEXT_CONTENT: 'Развернуть',
  COLLAPSE_TEXT_CONTENT: 'Свернуть',
  COMMON_VACANCY_TITLE: 'Вакансий',
  GOLAN: 'Голанские высоты',
  NORTH: 'Север',
  HAIFA: 'Хайфа и округ',
  CENTER: 'Центр',
  TEL_AVIV: 'Тель-Авив и округ',
  SHOUTH: 'Юг',
  JERUSALEM: 'Иерусалим',
  HOURLY_PAY_GRADE: 'Почасовая зарплата'
};

export default messages;
